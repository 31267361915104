export default {
  type: {
    required: true,
    message: 'Tipe belum dipilih!',
  },
  function: {
    required: true,
    message: 'Fungsi belum dipilih!',
  },
  url: [{ required: true, message: 'URL tidak boleh kosong!' }],
  method: [{ required: true, message: 'Metode tidak boleh kosong!' }],
}
